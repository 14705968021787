import { DRAFT_KEYS } from "../../config/drafts";

export const APP_BAR_CONFIG = {
  [DRAFT_KEYS.mystories]: {
    title: "MyStories",
    publish: {
      success: "Story published!",
      error: {
        noSkipChapter: {
          title: "Publish Story Error",
          desc: "To Publish a Story, please select the chapter to skip to.",
        },
        noURL: {
          title: "Publish Story Error",
          desc: "To Publish a Story, please fill out the chapters URL fields.",
        },
      },
    },
    overflow: ["previewDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.mystories_v2]: {
    title: "MyStories v2.0",
    publish: {
      success: "Story published!",
      error: {
        noSkipChapter: {
          title: "Publish Story Error",
          desc: "To Publish a Story, please select the chapter to skip to.",
        },
        noURL: {
          title: "Publish Story Error",
          desc: "To Publish a Story, please fill out the chapters URL fields.",
        },
      },
    },
    overflow: ["previewDraft", "upgradeDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.myguide]: {
    title: "MyGuide",
    publish: {
      success: "Guide published!",
      error: {
        noSkipChapter: {
          title: "Publish Guide Error",
          desc: "To Publish a Guide, please select the step to skip to.",
        },
        noURL: {
          title: "Publish Guide Error",
          desc: "To Publish a Guide, please fill out the steps URL fields.",
        },
      },
    },
    overflow: ["previewDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.mysignage]: {
    title: "MySignage",
    publish: {
      confirm: {
        title: "Sign Playtime Error",
        desc: "Your sign doesn't play from 00:00 to 24:00, a default chapter image will be shown when your sign isn't playing. If this is okay please proceed, otherwise update your chapters' playtimes to cover 24 hours.",
      },
      success: "Sign published!",
    },
    overflow: ["previewDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.myqr]: {
    title: "MyQR",
    publish: {
      error: {
        butonTextAndURL: {
          title: "Publish QR Error",
          desc: "To Publish a QR, please fill out the Button Text and Button URL fields.",
        },
      },
      success: "QR published!",
    },
    overflow: ["previewDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.mystorybook]: {
    title: "MyStorybook",
    publish: {
      success: "Storybook published!",
      error: {
        noSkipChapter: {
          title: "Publish Storybook Error",
          desc: "To Publish a Storybook, please select the chapter to skip to.",
        },
        noURL: {
          title: "Publish Storybook Error",
          desc: "To Publish a Storybook, please fill out the chapters URL fields.",
        },
      },
    },
    overflow: ["previewDraft", "upgradeDraft", "leaveDraft"],
  },
  [DRAFT_KEYS.myembed_content]: {
    title: "Embed",
    publish: {
      success: "Embed published!",
    },
    overflow: ["previewDraft", "upgradeDraft", "leaveDraft"],
  },
};
