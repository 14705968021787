import logo from "../assets/images/icon_my_player_v3.png";

export const ACCOUNTS_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3010"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://accounts-test.myplayer.io"
    : "https://accounts.myplayer.io";

export const CREATE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://create-test.myplayer.io"
    : "https://create.myplayer.io";

export const IFRAME_TEST_URL =
  "https://storage.googleapis.com/myplayertest.appspot.com";

export const IFRAME_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5502" // this will change depending how you're the hosting the mystories knockout template locally
    : process.env.REACT_APP_DEMO_MODE
    ? "https://storage.googleapis.com/myplayertest.appspot.com"
    : "https://storage.googleapis.com/myplayerbase.appspot.com";

export const LOGOUT_URL = `${ACCOUNTS_URL}/login`;

export const MYPROJECTS_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3002"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://app-test.myplayer.io"
    : "https://app.myplayer.io";

export const STORAGE_BUCKET = process.env.REACT_APP_DEMO_MODE
  ? "myplayertest.appspot.com"
  : "myplayerbase.appspot.com";

export const TABS = [
  {
    label: "Editor",
    value: "editor",
  },
  {
    label: "Team",
    value: "team",
  },
];

export const REQUEST_STATES = {
  requested: "requested",
  accepted: "accepted",
  rejected: "rejected",
  fulfilled: "fulfilled",
};

export const OWNED_BY_ITEMS = {
  anyone: "Owned by anyone",
  me: "Owned by me",
  notMe: "Not owned by me",
};

export const REQUEST_TIMEOUT_SECONDS = 10;

export const QR_CONFIG = {
  size: 256,
  includeMargin: true,
  imageSettings: {
    src: logo,
    height: 36,
    width: 36,
    excavate: true,
  },
};
