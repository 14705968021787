import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

import { Spinner } from "../../../components";
import RestoreDraftContent from "./RestoreDraftContent";
import UpgradeDraftContent from "./UpgradeDraftContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import STRINGS from "../../../strings/general";

import { useDraftIdContext } from "../../../context";
import {
  getUpgradeVersions,
  upgradeDraft,
  restoreDraft,
} from "../../../services/ApiService";
import { useDraft } from "../../../services/FirestoreService";
import { getDraftOriginalVersion } from "../../../services/DraftService";

const ManageVersionDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const draftId = useDraftIdContext();
  const [versionObject, setVersionObject] = useState(null);
  const [loading, setLoading] = useState(false);

  const draft = useDraft({ draftId });
  const ver = getDraftOriginalVersion(draft);

  // upgrade
  const [upgradeToVersion, setUpgradeToVersion] = useState("");
  const [createBackup, setCreateBackup] = useState(true);
  // restore
  const [restore, setRestore] = useState(false);

  const hasBackup = versionObject?.hasBackup;
  const hasNewerVersions = versionObject?.versions?.length > 0;
  var disableConfirm = loading;

  const resetStates = () => {
    setVersionObject(null);
    setRestore(false);
    setCreateBackup(true);
    setUpgradeToVersion("");
    setLoading(false);
  };

  const onCloseInternal = useCallback(() => {
    if (loading) return;
    if (onClose) onClose();
    resetStates();
  }, [loading, onClose]);

  const onConfirm = () => {
    if (!hasBackup && !hasNewerVersions) return;
    // upgrade
    setLoading(true);
    const api = hasBackup
      ? restoreDraft({ draftId })
      : upgradeDraft({ draftId, upgradeToVersion, createBackup });
    const msg = hasBackup ? STRINGS.draft.restored : STRINGS.draft.upgraded;
    api
      .then(() => {
        onCloseInternal();
        enqueueSnackbar(msg, { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(STRINGS.error, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // upgrade
  const onChangeUpgradeToVersion = (val) => {
    setUpgradeToVersion(val);
  };
  const onChangeCreateBackup = (val) => {
    setCreateBackup(val);
  };
  // restore
  const onChangeRestore = (val) => {
    setRestore(val);
  };

  useEffect(() => {
    if (!open) return;
    getUpgradeVersions({ draftId })
      .then((res) => {
        if (res?.versions?.length > 0) {
          setUpgradeToVersion(res.versions[res.versions.length - 1]);
        }
        setVersionObject(res);
      })
      .catch((err) => {
        enqueueSnackbar(STRINGS.error, { variant: "error" });
        onCloseInternal();
      });
  }, [draftId, enqueueSnackbar, onCloseInternal, open]);

  var content;
  if (!versionObject) {
    content = <Spinner />;
    disableConfirm = true;
  } else if (hasBackup) {
    content = (
      <RestoreDraftContent
        version={ver?.version}
        restore={restore}
        onChangeRestore={onChangeRestore}
        disabled={loading}
      />
    );
    disableConfirm |= !restore;
  } else if (hasNewerVersions) {
    content = (
      <UpgradeDraftContent
        versions={versionObject?.versions}
        upgradeToVersion={upgradeToVersion}
        createBackup={createBackup}
        onChangeUpgradeToVersion={onChangeUpgradeToVersion}
        onChangeCreateBackup={onChangeCreateBackup}
        disabled={loading}
      />
    );
    disableConfirm |= upgradeToVersion.length === 0;
  } else {
    content = STRINGS.draft.alreadyNewest;
    disableConfirm = true;
  }

  return (
    <Dialog open={open} onClose={onCloseInternal} fullWidth maxWidth="sm">
      {loading && (
        <Box sx={{ position: "absolute", top: 0, left: 0, right: 0 }}>
          <LinearProgress />
        </Box>
      )}
      <DialogTitle>{STRINGS.draft.manageTitle}</DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>{content}</DialogContent>
      <DialogActions>
        <IconButton onClick={onCloseInternal} disabled={disableConfirm}>
          <CloseIcon />
        </IconButton>
        <IconButton onClick={onConfirm} disabled={disableConfirm}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ManageVersionDialog;
