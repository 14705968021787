import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { getDraftRoute } from "../../../routes/routes";

import DraftTypeCard from "./DraftTypeCard";
import { DRAFT_TYPES } from "../../../config/drafts";
import STRINGS from "../../../strings/home";
import { createDraft } from "../../../services/ApiService";

const useStyles = makeStyles((theme) => ({
  dialogToolbar: {
    backgroundColor: theme.palette.grey.main,
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitle: {
    backgroundColor: theme.palette.grey.main,
    textAlign: "center",
    padding: "3rem 3rem 0",
    paddingTop: (props) => props.fullScreen && 0,
  },
  gridContainer: {
    backgroundColor: theme.palette.grey.main,
    padding: "2rem",
    textAlign: "center",
  },
  gridItem: {
    backgroundColor: theme.palette.grey.main,
  },
}));

const CreateDraftDialog = ({ open, handleClose }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ fullScreen });

  const onCreate = async ({ key }) => {
    try {
      setLoading(true);
      const resp = await createDraft({ draftType: key });
      setLoading(false);
      enqueueSnackbar(STRINGS.create_dialog.success, {
        variant: "success",
      });
      handleClose();
      // enter newly created draft page
      if (resp?.newDraftId) history.push(getDraftRoute(resp.newDraftId));
    } catch (e) {
      console.log(e);
      enqueueSnackbar(STRINGS.create_dialog.fail, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        scroll="body"
      >
        {loading && <LinearProgress />}
        {fullScreen && (
          <div className={classes.dialogToolbar}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <DialogTitle className={classes.dialogTitle}>
          {STRINGS.create_dialog.title}
        </DialogTitle>
        <Grid className={classes.gridContainer} container>
          {Object.keys(DRAFT_TYPES)
            .filter((key) => !DRAFT_TYPES[key].excludeFromHome)
            .map((key) => (
              <Grid key={key} className={classes.gridItem} sm={12} md={4} item>
                <DraftTypeCard
                  draftType={DRAFT_TYPES[key]}
                  loading={loading}
                  createDraft={onCreate}
                />
              </Grid>
            ))}
        </Grid>
      </Dialog>
    </>
  );
};

export default CreateDraftDialog;
