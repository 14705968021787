import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flex: "0 0 calc(34% - 0.46rem)",
    width: "100%",
    minWidth: "15rem",
  },
  card: {
    height: "20rem",
    width: "100%",
    minWidth: "15rem",
    margin: "1rem",
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",
    padding: "0.5rem",
  },
  cardImageContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    padding: "1rem",
  },
  cardImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "100%",
  },
}));

const DraftTypeCard = ({ draftType, createDraft, loading }) => {
  const { title, subtitle, key, image } = draftType;
  const classes = useStyles();
  const onClick = () => {
    if (loading) return;
    createDraft({ key });
  };
  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardActionArea
          className={classes.cardActionArea}
          disabled={loading}
          onClick={onClick}
        >
          <div className={classes.cardImageContainer}>
            <img src={image} alt={title} className={classes.cardImage} />
          </div>
          <CardContent>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {subtitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default DraftTypeCard;
