import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { isVersionSupported } from "../../../services/DraftService";

const MenuItemText = styled.span`
  margin-right: 0.5rem;
`;

const REQUIRE_VERSIONS = {
  blank_guide: "0.1",
  blank_story_v2: "0.10",
  blank_storybook: "0.1",
};

// version = { type: "", version: "" }
const PreviewDraftMenuItem = ({ action, version }) => {
  const [loading] = useState();

  const goToPreview = () => {
    if (loading) return;
    if (action) action();
  };
  // not meet required version
  let disabled;
  try {
    disabled = !isVersionSupported(version, REQUIRE_VERSIONS);
  } catch (err) {
    return <></>;
  }

  return (
    <MenuItem onClick={goToPreview} disabled={disabled}>
      <MenuItemText>Open Preview</MenuItemText>
    </MenuItem>
  );
};

export default PreviewDraftMenuItem;
