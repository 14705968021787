import axios from "axios";
import { Auth } from "../App";
import { getCookieByKey } from "../utils";
import { ACCOUNTS_URL, CREATE_URL } from "../config/config";

const AUTH_API_BASE = "/accountsApi";

const ACCOUNTS_REDIRECT_URL = `${ACCOUNTS_URL}/login?continue=${CREATE_URL}`;
export const redirectToAccounts = () => {
  window.location.href = ACCOUNTS_REDIRECT_URL;
};

export const getXssToken = async () => {
  const xssToken = getCookieByKey("xss");
  return xssToken;
};

export const ssoLogout = async () => {
  await sessionLogout();
  await signOut();
  redirectToAccounts();
};

export const checkAuthStatus = async () => {
  const xssToken = await getXssToken();
  if (!xssToken) {
    ssoLogout();
    return;
  }
  const response = await axios.post(
    `${AUTH_API_BASE}/checkAuthStatus`,
    {},
    {
      headers: { Authorization: `Bearer ${xssToken}` },
    }
  );
  if (response.data.status === "success") {
    const customToken = response.data.customToken;
    const user = await Auth.signInWithCustomToken(customToken);
    return user;
  }
};
export const signOut = () => Auth.signOut();

export const sessionLogout = async () => {
  try {
    await axios.post(`${AUTH_API_BASE}/sessionLogout`, {});
  } catch (error) {
    console.log(error);
  }
};
