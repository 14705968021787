import { createTheme } from "@material-ui/core/styles";

const MYPLAYER_ORANGE = "#F78130";

const theme = createTheme({
  palette: {
    primary: {
      main: MYPLAYER_ORANGE,
    },
    grey: {
      main: "#F5F5F5",
      dark: "#DCDCDC",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Gotham",
    h6: {
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
});

export default theme;
