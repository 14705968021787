import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { COLLECTIONS } from "../config/firebase";
import { Firestore } from "../App";
import { DRAFT_TYPES } from "../config/drafts";

const useCollection = (collection) => {
  useFirestoreConnect(collection && [{ collection }]);
  return useSelector((state) => state.firestore.data[collection]);
};

export const useDraftInviteCodes = ({ draftId }) => {
  const [draftcodes, setDraftcodes] = useState();

  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFTCODES,
      where: [
        ["draftId", "==", draftId],
        ["deleted", "==", false],
      ],
    },
  ]);

  const info = useSelector(({ firestore: { data } }) => data.draftcodes);
  useEffect(() => {
    if (info || info === null) {
      setDraftcodes(info);
    }
  }, [info]);
  return draftcodes;
};

export const useDraftInviteCode = ({ code }) => {
  const [draftcode, setDraftcode] = useState();
  useFirestoreConnect(
    code && [
      {
        collection: COLLECTIONS.DRAFTCODES,
        doc: code,
      },
    ]
  );

  const info = useSelector(
    ({ firestore: { data } }) => data.draftcodes && data.draftcodes[code]
  );
  useEffect(() => {
    if (info || info === null) {
      setDraftcode(info);
    }
  }, [info]);
  return draftcode;
};

export const useDraftCodeMembers = ({ code }) => {
  const [members, setMembers] = useState();
  useFirestoreConnect(
    code && [
      {
        collection: COLLECTIONS.USERS_AND_DRAFTS,
        where: [["joinCode", "==", code]],
        storeAs: `draftcodeMembers${code}`,
      },
    ]
  );

  const info = useSelector(
    ({ firestore: { data } }) => data[`draftcodeMembers${code}`]
  );
  useEffect(() => {
    if (info || info === null) {
      setMembers(info);
    }
  }, [info]);
  return members;
};

const useDraftsAndChapters = ({ draftId, collection }) => {
  useFirestoreConnect(
    draftId &&
      collection && [
        {
          collection,
          doc: draftId,
          subcollections: [{ collection: COLLECTIONS.CHAPTERS }],
          storeAs: COLLECTIONS.STORIES_AND_CHAPTERS,
          where: ["deleted", "!=", true],
        },
      ]
  );
  const chapters = useSelector(
    (state) => state.firestore.data.stories_and_chapters
  );
  return chapters;
};

export const useDraft = ({ draftId }) => {
  const [draft, setDraft] = useState();
  useFirestoreConnect(
    draftId && [
      {
        collection: COLLECTIONS.DRAFTS,
        doc: draftId,
      },
    ]
  );
  const info = useSelector(
    ({ firestore: { data } }) => data.drafts && data.drafts[draftId]
  );

  useEffect(() => {
    if (info || info === null) {
      setDraft(info);
    }
  }, [info]);
  return draft;
};

export const useDraftSessions = ({ draftId }) => {
  const [draftSessions, setDraftSessions] = useState();

  useFirestoreConnect(
    draftId && [
      {
        collection: COLLECTIONS.DRAFTS_AND_SESSIONS,
        where: ["draftId", "==", draftId],
      },
    ]
  );
  const info = useSelector(
    ({ firestore: { data } }) => data.drafts_and_sessions
  );

  useEffect(() => {
    if (info || info === null) {
      setDraftSessions(info);
    }
  }, [info]);
  return draftSessions;
};

export const useDraftEdit = ({ draftId }) => {
  const [draftEdit, setDraftEdit] = useState();

  useFirestoreConnect(
    draftId && [
      {
        collection: COLLECTIONS.DRAFTS_AND_EDIT,
        doc: draftId,
      },
    ]
  );
  const info = useSelector(
    ({ firestore: { data } }) =>
      data.drafts_and_edit && data.drafts_and_edit[draftId]
  );

  useEffect(() => {
    if (info || info === null) {
      setDraftEdit(info);
    }
  }, [info]);
  return draftEdit;
};

export const useUserDraftEdits = ({ userId }) => {
  const [userDraftEdits, setUserDraftEdits] = useState();
  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFTS_AND_EDIT,
      where: ["userId", "==", userId],
      storeAs: "userDraftEdits",
    },
  ]);

  const info = useSelector(({ firestore: { data } }) => data.userDraftEdits);
  useEffect(() => {
    if (info || info === null) {
      setUserDraftEdits(info);
    }
  }, [info]);
  return userDraftEdits;
};

export const useDraftSessionRequest = ({ draftId }) => {
  const [draftRequest, setDraftRequest] = useState();
  useFirestoreConnect(
    draftId && [
      {
        collection: COLLECTIONS.DRAFTS_AND_REQUESTS,
        doc: draftId,
      },
    ]
  );
  const info = useSelector(
    ({ firestore: { data } }) =>
      data.drafts_and_requests && data.drafts_and_requests[draftId]
  );

  useEffect(() => {
    if (info || info === null) {
      setDraftRequest(info);
    }
  }, [info]);
  return draftRequest;
};

const useDraftsAndStories = ({ draftId, collection }) => {
  useFirestoreConnect(
    draftId &&
      collection && [
        {
          collection,
          doc: draftId,
        },
      ]
  );
  return useSelector((state) => state.firestore.data[collection]);
};

export const useFontFamilies = () =>
  useCollection(COLLECTIONS.STORIES_AND_FONT_FAMILIES);

export const useFontWeights = () =>
  useCollection(COLLECTIONS.STORIES_AND_FONT_WEIGHTS);

export const useStoriesAndCTAs = () =>
  useCollection(COLLECTIONS.STORIES_AND_CTAS);

export const useStoriesAndCTAsAnimations = () =>
  useCollection(COLLECTIONS.STORIES_AND_CTAS_ANIMATIONS);

export const useStoriesAndStickers = () =>
  useCollection(COLLECTIONS.STORIES_AND_STICKERS);

export const useStoriesAndInteractions = () =>
  useCollection(COLLECTIONS.STORIES_AND_INTERACTIONS);

export const useDraftsAndResolutions = () =>
  useCollection(COLLECTIONS.DRAFTS_AND_RESOLUTIONS);

export const useDraftsAndLanguages = () =>
  useCollection(COLLECTIONS.DRAFTS_AND_LANGUAGES);

export const useStorybookSettings = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SETTINGS,
      doc: "mystorybook",
      storeAs: "mystorybook_settings",
    },
  ]);
  const mystorybook_settings = useSelector(
    (state) => state.firestore.data.mystorybook_settings
  );
  return mystorybook_settings;
};

export const useStory = ({ draftId, draftType }) => {
  const hasType = draftType in DRAFT_TYPES;
  const collection = hasType && DRAFT_TYPES[draftType].collection;
  const hasChapters = hasType && DRAFT_TYPES[draftType].hasChapters;
  const draftsAndStories = useDraftsAndStories({ draftId, collection });
  const chapters = useDraftsAndChapters({
    draftId: hasChapters && draftId,
    collection,
  });

  const [story, setStory] = useState();

  useEffect(() => {
    if (!draftId || !draftsAndStories) return;
    const draftedStory = {};

    Object.assign(draftedStory, draftsAndStories[draftId]);

    if (!hasChapters || !chapters) {
      setStory(draftedStory);
      return;
    }

    if (typeof chapters === "undefined") return;

    draftedStory.chapters = [];

    if (draftedStory.chapterOrder && draftedStory.chapterOrder.length) {
      draftedStory.chapterOrder.forEach((chapterId, index) => {
        if (!chapters[chapterId]) return;
        const chapter = {};
        Object.assign(chapter, chapters[chapterId]);
        chapter.id = chapterId;
        draftedStory.chapters[index] = chapter;
        draftedStory.chapters[index].id = chapterId;
      });
      setStory(draftedStory);
    }
  }, [draftId, draftsAndStories, chapters, hasChapters]);

  return story;
};

const useDraftsAndQR = ({ draftId }) => {
  useFirestoreConnect(
    draftId && [
      {
        collection: COLLECTIONS.DRAFTS_AND_QR,
        doc: draftId,
      },
    ]
  );

  const drafts_and_qr = useSelector(
    (state) => state.firestore.data.drafts_and_qr
  );
  return drafts_and_qr;
};

export const useQR = ({ draftId }) => {
  const drafts_and_qr = useDraftsAndQR({ draftId });
  const [qr, setQr] = useState();

  useEffect(() => {
    if (!draftId || !drafts_and_qr) return;
    const qr = drafts_and_qr[draftId];
    setQr(qr);
  }, [draftId, drafts_and_qr]);

  return qr;
};

export const setSubcollectionDocument = ({
  collection,
  doc,
  subcollection,
  subdoc,
  data,
}) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .doc(doc)
      .collection(subcollection)
      .doc(subdoc)
      .set(data, { merge: true })
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject();
      });
  });
};

export const updateSubCollectionDocument = ({
  collection,
  doc,
  subcollection,
  subdoc,
  data,
}) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .doc(doc)
      .collection(subcollection)
      .doc(subdoc)
      .update(data)
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
        reject();
      });
  });
};

export const setDocument = ({ collection, doc, data }) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .doc(doc)
      .set(data, { merge: true })
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject();
      });
  });
};

export const addDocument = ({ collection, data }) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        resolve(id);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject();
      });
  });
};

export const addSubCollectionDocument = ({
  collection,
  doc,
  subcollection,
  data,
}) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .doc(doc)
      .collection(subcollection)
      .add(data)
      .then(function (docRef) {
        resolve(docRef.id);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject();
      });
  });
};

export const deleteSubCollectionDocument = ({
  collection,
  doc,
  subcollection,
  subdoc,
}) => {
  return new Promise((resolve, reject) => {
    Firestore.collection(collection)
      .doc(doc)
      .collection(subcollection)
      .doc(subdoc)
      .delete()
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
        reject();
      });
  });
};

export const useUserDrafts = ({ userId }) => {
  const [drafts, setDrafts] = useState();
  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFTS,
      where: [
        ["users", "array-contains", userId],
        ["deleted", "!=", true],
      ],
      storeAs: "userDrafts",
    },
  ]);
  const info = useSelector((state) => state.firestore.data["userDrafts"]);
  useEffect(() => {
    if (info || info === null) {
      setDrafts(info);
    }
  }, [info]);
  return drafts;
};

export const createDocumentRef = ({ collection }) =>
  Firestore.collection(collection).doc();

export const useProjectInfo = (projectId) => {
  useFirestoreConnect(
    projectId && {
      collection: COLLECTIONS.PROJECT_INFO,
      doc: projectId,
    }
  );
  const docs = useSelector(
    (state) => state.firestore.data[COLLECTIONS.PROJECT_INFO]
  );
  if (!docs) return docs;
  return docs[projectId];
};

export const getDocument = ({ collection, doc }) =>
  Firestore.collection(collection)
    .doc(doc)
    .get()
    .then((doc) => doc.data());

export const getSubCollection = ({ collection, doc, subcollection }) => {
  return Firestore.collection(collection)
    .doc(doc)
    .collection(subcollection)
    .get()
    .then((querySnapshot) => {
      const documents = {};
      querySnapshot.forEach((doc) => {
        documents[doc.id] = {
          id: doc.id,
          ...doc.data(),
        };
      });
      return documents;
    });
};

export const deleteDocument = ({ collection, doc }) => {
  return Firestore.collection(collection).doc(doc).delete();
};
