import icon_mystories from "../assets/images/icon_mystories.png";
import icon_mysignage from "../assets/images/icon_mysignage.png";
import icon_myqr from "../assets/images/icon_myqr.png";
import icon_myguide from "../assets/images/icon_myguide.png";
import icon_mystorybook from "../assets/images/icon_storybook.png";
import icon_myembed_content from "../assets/images/icon_customapp.png";

export const DRAFT_KEYS = {
  mystories: "mystories",
  mystories_v2: "mystories_v2",
  mysignage: "mysignage",
  myqr: "myqr",
  myguide: "myguide",
  mystorybook: "mystorybook",
  myembed_content: "myembed_content",
};

export const DRAFT_TYPES = {
  [DRAFT_KEYS.mystories]: {
    key: DRAFT_KEYS.mystories,
    title: "MyStories",
    subtitle: "Snappy interactive stories",
    image: icon_mystories,
    iframeId: `${DRAFT_KEYS.mystories}-iframe`,
    collection: "drafts_and_stories",
    storageFolder: "stories",
    hasChapters: true,
    excludeFromHome: true,
  },
  [DRAFT_KEYS.mystories_v2]: {
    key: DRAFT_KEYS.mystories_v2,
    title: "MyStories",
    subtitle: "Snappy Interactive Stories",
    image: icon_mystories,
    iframeId: `${DRAFT_KEYS.mystories_v2}-iframe`,
    collection: "drafts_and_stories_v2",
    storageFolder: "storiesV2",
    hasChapters: true,
  },
  [DRAFT_KEYS.mystorybook]: {
    key: DRAFT_KEYS.mystorybook,
    title: "MyStoryBook",
    subtitle: "A Collection of Your Experiences",
    image: icon_mystorybook,
    iframeId: `${DRAFT_KEYS.mystorybook}-iframe`,
    collection: "drafts_and_storybook",
    storageFolder: "storybook",
    hasChapters: false,
  },
  [DRAFT_KEYS.mysignage]: {
    key: DRAFT_KEYS.mysignage,
    title: "MySignage",
    subtitle: "Video and Image Playlists",
    image: icon_mysignage,
    iframeId: `${DRAFT_KEYS.mysignage}-iframe`,
    collection: "drafts_and_signage",
    storageFolder: "signage",
    hasChapters: true,
  },
  [DRAFT_KEYS.myqr]: {
    key: DRAFT_KEYS.myqr,
    title: "MyQR",
    subtitle: "Redirect to Any Website",
    image: icon_myqr,
    iframeId: `${DRAFT_KEYS.myqr}-iframe`,
    collection: "drafts_and_qr",
    storageFolder: "qr",
    hasChapters: false,
  },
  [DRAFT_KEYS.myguide]: {
    key: DRAFT_KEYS.myguide,
    title: "MyGuide",
    subtitle: "Guided Experiences",
    image: icon_myguide,
    iframeId: `${DRAFT_KEYS.myguide}-iframe`,
    collection: "drafts_and_guide",
    storageFolder: "guide",
    hasChapters: true,
  },
  [DRAFT_KEYS.myembed_content]: {
    key: DRAFT_KEYS.myembed_content,
    title: "Custom App",
    subtitle: "BYO Experience",
    image: icon_myembed_content,
    iframeId: `${DRAFT_KEYS.myembed_content}-iframe`,
    collection: "drafts_and_embed_content",
    storageFolder: "embed_content",
    hasChapters: false,
  },
};
