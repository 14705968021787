import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { ViewCarouselOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useDraftPermissionsContext } from "../../context";
import { MobileContent, SideBarContent } from "../../components";

import { checkIsDraftCollaborative } from "../../services/DraftService";
import { ROUTES } from "../../routes/routes";
import STRINGS from "../../strings/general";
import { DraftContent } from "./DraftContent";

const Container = styled(Box)`
  flex: 1;
  overflow: hidden;
`;

const EditorTab = ({ draft, draftId }) => {
  const draftType = draft && draft.type;
  const theme = useTheme();
  const isDraftCollaborative = checkIsDraftCollaborative(draft);
  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sideBarConfig = {
    tabs: [
      {
        icon: <ViewCarouselOutlined />,
        label: "Drafts",
        route: ROUTES.home,
      },
    ],
  };

  return (
    <Container>
      {mobile ? (
        <MobileContent
          title={STRINGS.editor.mobile.title}
          desc={STRINGS.editor.mobile.desc}
        />
      ) : (
        <>
          {!canEdit && isDraftCollaborative && (
            <SideBarContent config={sideBarConfig} />
          )}
          <DraftContent draftType={draftType} draftId={draftId} />
        </>
      )}
    </Container>
  );
};

export default EditorTab;
export { DraftContent };
