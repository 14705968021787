import React, { useRef } from "react";

import {
  useMessageListener,
  usePostDraftEdit,
  usePostUserPermissions,
} from "../../services/MessageService";
import { useIframeUrl } from "../../services/DraftService";
import { DRAFT_TYPES } from "../../config/drafts";
import { Auth } from "../../App";

const IframeContent = ({ draftId, draftType, preview, postMessage }) => {
  const currentUserId = Auth.currentUser.uid;
  const id = DRAFT_TYPES[draftType].iframeId;
  const title = DRAFT_TYPES[draftType].title;

  const iframe = useRef(null);

  const iframeSrc = useIframeUrl({ draftId, draftType, preview });

  const iframeReady = useMessageListener({
    targetWindow: iframe.current,
    draftId,
  });

  usePostDraftEdit({
    draftId,
    targetWindow: iframe.current,
    iframeReady,
    currentUserId,
  });

  usePostUserPermissions({ targetWindow: iframe.current, iframeReady });

  // specific postMessage for each draft type
  postMessage({
    userId: currentUserId,
    draftId,
    draftType,
    targetWindow: iframe.current,
    iframeReady,
  });

  return (
    <>
      {iframeSrc && (
        <iframe
          id={id}
          ref={iframe}
          title={title}
          src={iframeSrc}
          style={{ height: "100%", width: "100%", borderWidth: 0 }}
        ></iframe>
      )}
    </>
  );
};

export default IframeContent;
