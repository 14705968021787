import {
  usePostMyStoryData,
  usePostStorybookMessage,
  usePostMySignageData,
  usePostMyQRData,
  usePostEmbedContentMessage,
} from "../../services/MessageService";
import { DRAFT_TYPES } from "../../config/drafts";
import IframeContent from "./IframeContent";

export const DraftContent = ({ draftType, draftId, preview }) => {
  if (typeof draftType === "undefined" || typeof draftId === "undefined")
    return null;

  if (!(draftType in DRAFT_TYPES))
    return <div>Draft type: {draftType} not supported yet</div>;

  // Config for drafts types that need to be rendered in the iframe
  const postMessageMap = {
    [DRAFT_TYPES.mystories.key]: usePostMyStoryData,
    [DRAFT_TYPES.mystories_v2.key]: usePostMyStoryData,
    [DRAFT_TYPES.myguide.key]: usePostMyStoryData,
    [DRAFT_TYPES.mystorybook.key]: usePostStorybookMessage,
    [DRAFT_TYPES.myembed_content.key]: usePostEmbedContentMessage,
    [DRAFT_TYPES.mysignage.key]: usePostMySignageData,
    [DRAFT_TYPES.myqr.key]: usePostMyQRData,
  };

  const postMessage = postMessageMap[draftType];

  if (postMessage) {
    return (
      <IframeContent
        draftId={draftId}
        draftType={draftType}
        preview={preview}
        postMessage={postMessage}
      />
    );
  }

  return <div>Draft type not supported</div>;
};
