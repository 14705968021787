import { Storage, Auth } from "../App";

const DELIMITER = ";";

export const uploadFile = async ({ path, file }) => {
  if (!file || !path) {
    throw new Error("uploadFile requires a path and file");
  }
  const ref = Storage.child(path);
  const currentUser = Auth.currentUser;

  await ref.put(file);

  // metadata used for storage security rules

  // Example format
  // allowedUsers: userId1;userId2;userId3

  // NOTE: metadata will need to be updated if we decide to have multiple users per story
  const metadata = await ref.getMetadata();
  const allowedUsersString = metadata.customMetadata?.allowedUsers;
  let newAllowedUsersString;
  if (allowedUsersString) {
    const splitArray = allowedUsersString.split(DELIMITER);
    splitArray.push(`${DELIMITER}${currentUser.uid}`);
    newAllowedUsersString = splitArray.join(DELIMITER);
  } else {
    newAllowedUsersString = `${currentUser.uid}`;
  }
  const newMetadata = {
    customMetadata: {
      allowedUsers: newAllowedUsersString,
    },
  };
  await ref.updateMetadata(newMetadata);

  return await ref.getDownloadURL();
};

export const getDownloadUrl = async ({ path }) => {
  if (!path) return;
  const ref = Storage.child(path);
  return await ref.getDownloadURL();
};

export const deleteItem = async ({ path }) => {
  if (!path) return;
  const ref = Storage.child(path);
  try {
    await Storage.child(ref.fullPath).delete();
  } catch (e) {
    console.log(e);
    return;
  }
};

// prefix = folder
// return number of prefixes + items deleted
const deletePrefix = async (ref) => {
  const res = await ref.listAll();
  const prefixPromises = res.prefixes.map((ref) => deletePrefix(ref));
  // items include the ref itself
  const itemPromises = res.items.map((ref) =>
    deleteItem({ path: ref.fullPath })
  );
  const deleteRes = await Promise.all([...prefixPromises, ...itemPromises]);
  return deleteRes.reduce((p, c) => p + c, 0);
};

export const deletePath = async ({ path }) => deletePrefix(Storage.child(path));
